<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-02-02 15:57:14
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-02-02 16:55:29
 * @FilePath: /yiyun_project/src/views/shortVideo/packageManag.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-main>
    <div class="btnBox">
      <el-button size="mini" type="primary" @click="addTaskDialog">+ 添加套餐</el-button>
    </div>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="套餐名称：">
        <el-input size="small" placeholder="请输入套餐名称" v-model="dataInfo.name"></el-input>
      </el-form-item>
      <el-form-item label="创建时间：">
        <el-date-picker v-model="dataInfo.create_time" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="getPackList(1)">搜索</el-button>
        <el-button size="small" type="text" @click="resetSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataInfo.list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="套餐名称" align="center"></el-table-column>
      <el-table-column prop="label" label="标签" align="center"></el-table-column>
      <el-table-column prop="all_integral" label="套餐总积分" align="center"></el-table-column>
      <el-table-column prop="real_integral" label="套餐积分" align="center"></el-table-column>
      <el-table-column prop="send_integral" label="套餐赠送积分" align="center"></el-table-column>
      <el-table-column prop="official_price" label="套餐原价" align="center">
        <template v-slot="{ row }">{{ parseFloat(row.official_price).toFixed(2) }}</template>
      </el-table-column>
      <el-table-column prop="sell_price" label="套餐售卖价格" align="center">
        <template v-slot="{ row }">{{ parseFloat(row.sell_price).toFixed(2) }}</template>
      </el-table-column>
      <el-table-column prop="short_desc" label="套餐描述" align="center"></el-table-column>
      <el-table-column label="创建时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" @click="editRow(row)">编辑</el-button>
          <el-button type="text" @click="deleteTask(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="dataInfo.total_number" :page="dataInfo.page" :pageNum="dataInfo.rows" @updatePageNum="updateData"></Paging>
    <!-- 操作弹窗 -->
    <el-dialog :title="dialogInfo.id ? '编辑套餐' : '添加套餐'" :visible.sync="showDialog" width="600rpx">
      <el-form label-width="120px" class="demo-ruleForm">
        <el-form-item label="名称">
          <el-input style="width: 220px" v-model="dialogInfo.name" placeholder="请输入任务名称"></el-input>
        </el-form-item>
        <!-- <el-form-item label="图片">
          <el-input style="width: 220px" v-model="dialogInfo.task_name" placeholder="请输入任务名称"></el-input>
        </el-form-item> -->
        <el-form-item label="标识">
          <el-input style="width: 220px" v-model="dialogInfo.label" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input style="width: 220px" type="textarea" :rows="2" v-model="dialogInfo.short_desc" placeholder="请输入套餐描述"></el-input>
        </el-form-item>
        <el-form-item label="套餐总积分">
          <el-input style="width: 220px" type="number" v-model="dialogInfo.all_integral" placeholder="请输入总积分"></el-input>
        </el-form-item>
        <el-form-item label="套餐积分">
          <el-input style="width: 220px" type="number" v-model="dialogInfo.real_integral" placeholder="请输入套餐积分"></el-input>
        </el-form-item>
        <el-form-item label="套餐赠送积分">
          <el-input style="width: 220px" type="number" v-model="dialogInfo.send_integral" placeholder="请输入赠送积分"></el-input>
        </el-form-item>
        <el-form-item label="原价">
          <el-input style="width: 220px" type="number" v-model="dialogInfo.official_price" placeholder="请输入原价"></el-input>
        </el-form-item>
        <el-form-item label="现价">
          <el-input style="width: 220px" type="number" v-model="dialogInfo.sell_price" placeholder="请输入现价"></el-input>
        </el-form-item>
        <!-- <el-form-item label="划线价格">
          <el-input style="width: 220px" v-model="dialogInfo.integral" placeholder="请输入赠送积分"></el-input>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-input style="width: 220px" v-model="dialogInfo.integral" placeholder="请输入赠送积分"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input style="width: 220px" v-model="dialogInfo.integral" placeholder="请输入赠送积分"></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="sureGrant">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>
<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      getDateformat,
      dataInfo: {
        page: 1,
        rows: 10,
        name: '',
        create_time: [],
        list: [],
        total_number: 0,
      },
      showDialog: false,
      dialogInfo: {
        name: '',
        short_desc: '',
        official_price: '',
        sell_price: '',
        all_integral: '',
        real_integral: '',
        send_integral: '',
        label: '',
      },
    };
  },
  created() {
    this.getPackList();
  },
  methods: {
    resetSearch() {
      this.dataInfo = {
        page: 1,
        rows: 10,
        name: '',
        create_time: [],
        list: [],
        total_number: 0,
      };
      this.getPackList();
    },
    sureGrant() {
      let flag = true;
      for (const key in this.dialogInfo) {
        if (!this.dialogInfo[key]) {
          console.log(this.dialogInfo[key], key);
          flag = false;
          break;
        }
      }
      if (!flag) {
        this.$message.warning('请填写合规数据');
        return;
      }
      let url = this.$api.shortVideo.addPack;
      let obj = _.cloneDeep(this.dialogInfo);
      let str = '添加成功';
      if (obj.id) {
        url = this.$api.shortVideo.editPack;
        str = '编辑成功';
      }
      this.$axios.post(url, obj).then(res => {
        if (res.code == 0) {
          this.$message.success(str);
          this.getPackList();
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    editRow(data) {
      this.dialogInfo.id = null;
      for (const key in this.dialogInfo) {
        this.dialogInfo[key] = data[key];
      }
      this.showDialog = true;
    },
    deleteTask(data) {
      this.$confirm('此操作将删除该套餐, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.shortVideo.deletePack, {
            id: data.id,
          });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('操作成功');
            this.getPackList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    addTaskDialog() {
      this.dialogInfo = {
        name: '',
        short_desc: '',
        official_price: '',
        sell_price: '',
        all_integral: '',
        real_integral: '',
        send_integral: '',
        label: '',
      };
      this.showDialog = true;
    },
    updateData(val, status) {
      if (status == 0) {
        this.dataInfo.rows = val;
      } else {
        this.dataInfo.page = val;
      }
      this.getPackList();
    },
    getPackList(style) {
      let obj = {
        page: this.dataInfo.page,
        rows: this.dataInfo.rows,
      };
      if (this.dataInfo.name) obj.name = this.dataInfo.name;
      if (this.dataInfo.create_time?.length) {
        let data = this.dataInfo.create_time;
        obj.create_time = [Math.floor(data[0] / 1000), Math.floor(data[1] / 1000)];
        if (obj.create_time[1] == obj.create_time[0]) {
          obj.create_time[1] = obj.create_time[0] + 60 * 60 * 24 - 1;
        }
      }
      this.$axios.post(this.$api.shortVideo.packList, obj).then(res => {
        if (res.code == 0) {
          this.dataInfo.list = res.result.list;
          this.dataInfo.total_number = res.result.total_number;
          this.showDialog = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .btnBox {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
}
</style>
